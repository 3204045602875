var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tem_content" },
    [
      _c("el-table-column", { key: "kong", attrs: { width: "1" } }),
      _vm.hasSelection
        ? _c("el-table-column", {
            key: "hasSelection",
            attrs: {
              type: "selection",
              align: "center",
              width: "55",
              selectable: _vm.selecheck,
              fixed: _vm.fix,
            },
          })
        : _vm._e(),
      _vm.hasRadio
        ? _c("el-table-column", {
            key: "hasRadio",
            attrs: { width: "55", align: "center" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: scope.row.id },
                          model: {
                            value: _vm.hasRadioModel,
                            callback: function ($$v) {
                              _vm.hasRadioModel = $$v
                            },
                            expression: "hasRadioModel",
                          },
                        },
                        [_vm._v(_vm._s(""))]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1408057648
            ),
          })
        : _vm._e(),
      _vm._l(_vm.headerList, function (headerItem, index) {
        return [
          headerItem.key == "custType" || headerItem.key == "supplierName"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "email" ||
              headerItem.key == "sName" ||
              headerItem.key == "name"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "220",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "applyType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.filterapplytype(scope.row.applyType))
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "adjType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: { isEdit: false, typeId: "SYS_ADJYTPE" },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "transferType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_SHIPTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "inFlg"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.isKcll
                            ? [
                                scope.row.inFlg == 0
                                  ? _c(
                                      "span",
                                      { staticClass: "color-success" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filterInFlgType(
                                              scope.row[headerItem.key]
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : scope.row.inFlg == 1
                                  ? _c(
                                      "span",
                                      { staticClass: "color-normal" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filterInFlgType(
                                              scope.row[headerItem.key]
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : scope.row.inFlg == 2
                                  ? _c(
                                      "span",
                                      { staticClass: "color-danger" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filterInFlgType(
                                              scope.row[headerItem.key]
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : scope.row.inFlg == 3
                                  ? _c(
                                      "span",
                                      { staticClass: "color-warning" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filterInFlgType(
                                              scope.row[headerItem.key]
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : scope.row.inFlg == 4
                                  ? _c("span", { staticClass: "color-info" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.filterInFlgType(
                                            scope.row[headerItem.key]
                                          )
                                        )
                                      ),
                                    ])
                                  : scope.row.inFlg == 5
                                  ? _c(
                                      "span",
                                      { staticClass: "color-prepare" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filterInFlgType(
                                              scope.row[headerItem.key]
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _c(
                                "span",
                                {
                                  class: scope.row.inFlg
                                    ? "color-success"
                                    : "color-danger",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.filterInFlgType(
                                        scope.row[headerItem.key]
                                      )
                                    )
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "stockType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.filterOperateType(scope.row[headerItem.key])
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "planOutPickTime"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "120",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row[headerItem.key]
                                  ? scope.row[headerItem.key].substr(0, 10)
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "checkType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_PANDIANTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "isAllowOut"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.isAllowOut
                                ? "color-success"
                                : "color-danger",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.filterLockyn(scope.row[headerItem.key])
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "stockCode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsStockMst",
                                },
                                model: {
                                  value: scope.row.stockCode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "stockCode", $$v)
                                  },
                                  expression: "scope.row.stockCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsAreaCode" && !_vm.isKqbm
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsGoodsAreaMst",
                                },
                                model: {
                                  value: scope.row.goodsAreaCode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "goodsAreaCode", $$v)
                                  },
                                  expression: "scope.row.goodsAreaCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsAreaCode" && _vm.isKqbm
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.goodsAreaCode))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsPosCode" && !_vm.isYxWx
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsGoodsPosMst",
                                },
                                model: {
                                  value: scope.row.goodsPosCode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "goodsPosCode", $$v)
                                  },
                                  expression: "scope.row.goodsPosCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsPosCode" && _vm.isYxWx
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsGoodsPosMst",
                                },
                                model: {
                                  value: scope.row.goodsPosCode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "goodsPosCode", $$v)
                                  },
                                  expression: "scope.row.goodsPosCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "targetStockCode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsStockMst",
                                },
                                model: {
                                  value: scope.row.targetStockCode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "targetStockCode", $$v)
                                  },
                                  expression: "scope.row.targetStockCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "targetGoodsAreaCode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsGoodsAreaMst",
                                },
                                model: {
                                  value: scope.row.targetGoodsAreaCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "targetGoodsAreaCode",
                                      $$v
                                    )
                                  },
                                  expression: "scope.row.targetGoodsAreaCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "targetGoodsPosCode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-table", {
                                attrs: {
                                  isEdit: false,
                                  TableName: "WmsGoodsPosMst",
                                },
                                model: {
                                  value: scope.row.targetGoodsPosCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "targetGoodsPosCode",
                                      $$v
                                    )
                                  },
                                  expression: "scope.row.targetGoodsPosCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "reponse" && _vm.isJdll
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("select-type", {
                            attrs: {
                              isEdit: false,
                              typeId: "SYS_GOODSCHANGE_REASON",
                            },
                            model: {
                              value: scope.row.reponse,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "reponse", $$v)
                              },
                              expression: "scope.row.reponse",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "applyReponse" && _vm.isQtfh
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.filterothersendResponse(
                                  scope.row.applyReponse
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "applyReponse" && _vm.isQtsh
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.filterothersendResponse(
                                  scope.row.applyReponse
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "pickCount" && _vm.isZb
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  align: "center",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-input-number", {
                            attrs: {
                              step: 1,
                              "step-strictly": "",
                              type: "text",
                              size: "mini",
                              disabled: !scope.row.dis,
                              min: 0,
                              max: scope.row.num / scope.row.standNum,
                            },
                            model: {
                              value: scope.row.pickCount,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "pickCount", $$v)
                              },
                              expression: "scope.row.pickCount",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "qualityFlg"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  "min-width": "120px",
                  label: headerItem.description,
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_QUALITYFLAG",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsAreaType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  "min-width": "120px",
                  label: headerItem.description,
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_GOODSAREATYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "managerId"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  "min-width": "120px",
                  label: headerItem.description,
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("filterManager")(
                                  scope.row[headerItem.key]
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "usedFlg"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.usedFlg
                                ? "color-danger"
                                : "color-success",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterlotMixtureFlg")(
                                    scope.row[headerItem.key]
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "putAwayRestricted"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.putAwayRestricted
                                ? "color-danger"
                                : "color-success",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterforbidden")(
                                    scope.row[headerItem.key]
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "retrievalRestricted"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.retrievalRestricted
                                ? "color-danger"
                                : "color-success",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterforbidden")(
                                    scope.row[headerItem.key]
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "empty"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.empty
                                ? "color-success"
                                : "color-danger",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("filterlotMixtureFlg")(
                                    scope.row[headerItem.key]
                                  )
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "externalType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_EXTERNALTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "instockType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_INBOUNDTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_GOODSTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "orderType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_INBOUNDTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "operatorId"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "outstockType" ||
              headerItem.key == "outStockType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_OUTSTOTYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "status"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                class: _vm._f("CstatusFilter")(
                                  scope.row.status
                                ),
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_ORDERSTATUS",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : (headerItem.key == "orderCode" ||
                headerItem.key == "taskCode" ||
                headerItem.key == "planId") &&
              !_vm.isCgsjrw
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  width: "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "taskCode" && _vm.isCgsjrw
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  sortable: "",
                  prop: headerItem.key,
                  label: headerItem.description,
                  width: "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "eRPCode" && _vm.isCgsjrw
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  prop: headerItem.key,
                  label: headerItem.description,
                  width: "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row["erpCode"]))]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "createNextYn"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  prop: headerItem.key,
                  label: headerItem.description,
                  width: "130px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.createNextYn
                                ? "color-danger"
                                : "color-success",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.filterPicking(scope.row.createNextYn)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "externalNo" && !_vm.isScckjhrw
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.isCgdhtzd
                            ? _c(
                                "span",
                                {
                                  staticClass: "ruleSpan ellipsis",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleJumpPage(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row[headerItem.key]))]
                              )
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[headerItem.key])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsName" && !_vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsName" && _vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "containerId"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "140px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "linkcolor",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleJumpPage(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row[headerItem.key]))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsId" && _vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "160px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsId" && _vm.isCgsjrw
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  filters: _vm.screen,
                  "filter-method": _vm.filterHandler,
                  sortable: "",
                  label: headerItem.description,
                  "min-width": "160px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsModel" && !_vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsModel" && _vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsBatch" && !_vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "goodsBatch" && _vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "inBatchCode" && _vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "ExpireDate" && _vm.isSskc
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "180px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "venCode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "300px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "xgdj"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "300px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          !scope.row.externalType
                            ? _c("span", [_vm._v("/")])
                            : _c(
                                "span",
                                {
                                  staticClass: "linkcolor",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleJumpPage(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c("select-type", {
                                        staticStyle: { display: "inline" },
                                        attrs: {
                                          isEdit: false,
                                          typeId: "SYS_EXTERNALTYPE",
                                        },
                                        model: {
                                          value: scope.row.externalType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "externalType",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.externalType",
                                        },
                                      }),
                                      _vm._v(" —— "),
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.externalNo)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "isPurchaser"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.isPurchaser
                                ? "color-success"
                                : "color-danger",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.filterYesNoStatus(scope.row.isFactory)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "isFactory"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.isFactory
                                ? "color-success"
                                : "color-danger",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.filterYesNoStatus(scope.row.isFactory)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "planTime"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "160px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row[headerItem.key]
                                  ? scope.row[headerItem.key].substring(
                                      0,
                                      scope.row[headerItem.key].lastIndexOf(" ")
                                    )
                                  : scope.row[headerItem.key]
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "address"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "220px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "num"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "standUnitName"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "barcode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "160px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "isProductLot"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              class: scope.row.isProductLot
                                ? "color-success"
                                : "color-danger",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.filterYesNoStatus(scope.row.isProductLot)
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "barcodeType"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "100px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            [
                              _c("select-type", {
                                attrs: {
                                  isEdit: false,
                                  typeId: "SYS_BARCODETYPE",
                                },
                                model: {
                                  value: scope.row[headerItem.key],
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, headerItem.key, $$v)
                                  },
                                  expression: "scope.row[headerItem.key]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "model"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "priority"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "250px",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.filterpriorityData(scope.row.priority))
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "relatedCode"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "150px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.isCgsjrw
                            ? _c(
                                "span",
                                {
                                  staticClass: "linkcolor",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleJumpPage(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row[headerItem.key]))]
                              )
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[headerItem.key])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "externalNo" && _vm.isScckjhrw
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.isScckjhrw
                            ? _c(
                                "span",
                                {
                                  staticClass: "linkcolor",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleJumpPage(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row[headerItem.key]))]
                              )
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[headerItem.key])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "mesCreateUserName" && _vm.isscllsq
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row[headerItem.key] == "" ||
                                  scope.row[headerItem.key] == null
                                  ? scope.row["createUserName"]
                                  : scope.row[headerItem.key]
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.key == "mesCreateUserName" && !_vm.isscllsq
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "120px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row[headerItem.key])),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : headerItem.type == "DateTime" ||
              headerItem.key == "pickingStartTime" ||
              headerItem.key == "pickingEndTime" ||
              headerItem.key == "sourceGoodsBatch" ||
              headerItem.key == "targetGoodsBatch"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  prop: headerItem.key,
                  label: headerItem.description,
                  "show-overflow-tooltip": "",
                  "min-width": "160px",
                  align: "center",
                },
              })
            : headerItem.key == "inStockDate"
            ? _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "160px",
                  align: "center",
                  "show-overflow-tooltip": "",
                  prop: headerItem.key,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row[headerItem.key].length > 19
                                  ? scope.row[headerItem.key].substring(
                                      0,
                                      scope.row[headerItem.key].lastIndexOf(" ")
                                    )
                                  : scope.row[headerItem.key]
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _c("el-table-column", {
                key: index,
                attrs: {
                  label: headerItem.description,
                  "min-width": "180px",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          headerItem.key == "enable"
                            ? _c("span", [
                                _vm.isYxWx
                                  ? _c(
                                      "span",
                                      {
                                        class: scope.row.enable
                                          ? "color-success"
                                          : "color-danger",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.filterenable(
                                                scope.row[headerItem.key]
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm.isZdfl
                                  ? _c(
                                      "span",
                                      {
                                        class: scope.row.enable
                                          ? "color-success"
                                          : "color-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.filterBasicState(
                                              scope.row.enable
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        class: scope.row.enable
                                          ? "color-success"
                                          : "color-danger",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.filteruseryn(
                                                scope.row[headerItem.key]
                                              )
                                            )
                                        ),
                                      ]
                                    ),
                              ])
                            : headerItem.key === "privilegeRules"
                            ? _c(
                                "span",
                                {
                                  staticClass: "ruleSpan ellipsis",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row[headerItem.key]))]
                              )
                            : _c("span", [
                                _vm._v(_vm._s(scope.row[headerItem.key])),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
        ]
      }),
      _vm.hasOperation
        ? _c("el-table-column", {
            key: "hasOperation",
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              "min-width": "180",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpdate(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      scope.row.disable != true
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleModifyStatus(scope.row, true)
                                },
                              },
                            },
                            [_vm._v("停用")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              66057566
            ),
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }