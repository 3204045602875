var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isEdit
        ? _c("span", [_vm._v(_vm._s(_vm.labelName))])
        : [
            _vm.TableName != "User"
              ? [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: _vm.size,
                        disabled: _vm.disabled,
                        value: _vm.value,
                        filterable: "",
                        placeholder: _vm.placeholder,
                      },
                      on: { change: _vm.handleChange },
                    },
                    _vm._l(_vm.typeDatas, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          value: item[_vm.defaultProps.value],
                          label: item[_vm.defaultProps.label],
                        },
                      })
                    }),
                    1
                  ),
                ]
              : [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: _vm.size,
                        disabled: _vm.disabled,
                        value: _vm.value,
                        filterable: "",
                        placeholder: _vm.placeholder,
                      },
                      on: { change: _vm.handleChange },
                    },
                    _vm._l(
                      _vm.$store.state.goodsLiable,
                      function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            value: item[_vm.defaultProps.value],
                            label: item[_vm.defaultProps.label],
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }