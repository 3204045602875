<template>
  <div class="tem_content">
    <!-- 占位，解决for循环后第一列变成最后一列的问题 -->
    <!-- <el-table-column width="1" v-if="!hasRadio && !hasSelection" key="kong"></el-table-column> -->
    <el-table-column width="1" key="kong"></el-table-column>
    <!-- 多选 -->
    <el-table-column type="selection" align="center" width="55" :selectable="selecheck" :fixed="fix" v-if="hasSelection" key="hasSelection" ></el-table-column>
    <!-- 单选 -->
    <!-- <el-table-column width="55" align="center" fixed="left" v-if="hasRadio" key="hasRadio"> -->
    <el-table-column width="55" align="center" v-if="hasRadio" key="hasRadio">
      <template slot-scope="scope">
        <el-radio :label="scope.row.id" v-model="hasRadioModel">{{"" }}</el-radio>
      </template>
    </el-table-column>
    <template v-for="(headerItem,index) in headerList">
      <!-- 客户类型处理 -->
      <el-table-column  :label="headerItem.description" :key="index" v-if="headerItem.key == 'custType' || headerItem.key == 'supplierName'" min-width="250" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 电子邮件、公司简称 -->
      <el-table-column  :label="headerItem.description" :key="index" v-else-if="headerItem.key == 'email' || headerItem.key == 'sName' || headerItem.key == 'name'" min-width="220"  show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-申请类型 -->
      <el-table-column v-else-if="headerItem.key == 'applyType'" :key="index" :label="headerItem.description" align="center" show-overflow-tooltip  min-width="120" >
        <template slot-scope="scope">
           <span  >{{ filterapplytype(scope.row.applyType)}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-调整类型 -->
      <el-table-column v-else-if="headerItem.key == 'adjType'" :key="index" :label="headerItem.description" align="center" show-overflow-tooltip min-width="120" >
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_ADJYTPE" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 仓储中心-调整类型 -->
      <el-table-column v-else-if="headerItem.key == 'transferType'" :key="index" :label="headerItem.description" align="center" show-overflow-tooltip min-width="120" >
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_SHIPTYPE" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 仓储中心-进出标志/库存履历进出标志 -->
      <el-table-column v-else-if="headerItem.key == 'inFlg'" :key="index" :label="headerItem.description" align="center" show-overflow-tooltip min-width="120" >
        <template slot-scope="scope">
          <template  v-if="isKcll">
            <span class="color-success" v-if="scope.row.inFlg == 0">{{ filterInFlgType(scope.row[headerItem.key])}}</span>
            <span class="color-normal" v-else-if="scope.row.inFlg == 1">{{ filterInFlgType(scope.row[headerItem.key])}}</span>
            <span class="color-danger" v-else-if="scope.row.inFlg == 2">{{ filterInFlgType(scope.row[headerItem.key])}}</span>
            <span class="color-warning" v-else-if="scope.row.inFlg == 3">{{ filterInFlgType(scope.row[headerItem.key])}}</span>
            <span class="color-info" v-else-if="scope.row.inFlg == 4">{{ filterInFlgType(scope.row[headerItem.key])}}</span>
            <span class="color-prepare" v-else-if="scope.row.inFlg == 5">{{ filterInFlgType(scope.row[headerItem.key])}}</span>
          </template>
          <span :class="scope.row.inFlg?'color-success':'color-danger'" v-else>{{filterInFlgType(scope.row[headerItem.key]) }}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-库存变动类型 -->
      <el-table-column v-else-if="headerItem.key == 'stockType' " :key="index" :label="headerItem.description" align="center" show-overflow-tooltip min-width="120" >
        <template slot-scope="scope">
          <span >{{ filterOperateType(scope.row[headerItem.key])}}</span>
        </template>
      </el-table-column>
      <!-- 计划领料日期 -->
      <el-table-column v-else-if="headerItem.key == 'planOutPickTime' " :key="index" :label="headerItem.description" align="center" show-overflow-tooltip min-width="120" >
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]?scope.row[headerItem.key].substr(0,10):""}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-盘点类型 -->
      <el-table-column v-else-if="headerItem.key == 'checkType'" :key="index" :label="headerItem.description" align="center" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_PANDIANTYPE" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 是否锁定 -->
      <el-table-column v-else-if="headerItem.key == 'isAllowOut'" :key="index" :label="headerItem.description" align="center" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="scope.row.isAllowOut?'color-success':'color-danger'"> <span >{{ filterLockyn(scope.row[headerItem.key])}}</span></span>
        </template>
      </el-table-column>
       <!-- 货物移动-仓库名称 -->
      <el-table-column v-else-if="headerItem.key == 'stockCode'" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsStockMst" v-model="scope.row.stockCode"></select-table></span>
        </template>
      </el-table-column>
       <!-- 货物移动-库区名称 -->
      <el-table-column v-else-if="headerItem.key == 'goodsAreaCode' && !isKqbm" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsGoodsAreaMst" v-model="scope.row.goodsAreaCode"></select-table></span>
        </template>
      </el-table-column>
        <!-- 库区管理  展示编码 -->
      <el-table-column v-else-if="headerItem.key == 'goodsAreaCode' && isKqbm" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.goodsAreaCode}}</span>
        </template>
      </el-table-column>
       <!-- 货物移动-库位名称 -->
      <el-table-column v-else-if="headerItem.key == 'goodsPosCode' && !isYxWx" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsGoodsPosMst" v-model="scope.row.goodsPosCode"></select-table></span>
        </template>
      </el-table-column>
      <!-- 基础配置-库位管理 -->
      <el-table-column v-else-if="headerItem.key == 'goodsPosCode' && isYxWx" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsGoodsPosMst" v-model="scope.row.goodsPosCode"></select-table></span>
           <!-- <span>{{scope.row[headerItem.key]}}</span> -->
        </template>
      </el-table-column>
    
      <!-- 仓储中心-目标仓库名称 -->
      <el-table-column v-else-if="headerItem.key == 'targetStockCode'" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsStockMst" v-model="scope.row.targetStockCode"></select-table></span>
        </template>
      </el-table-column>
      <!-- 仓储中心-目标库区名称 -->
      <el-table-column v-else-if="headerItem.key == 'targetGoodsAreaCode'" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsGoodsAreaMst" v-model="scope.row.targetGoodsAreaCode"></select-table></span>
        </template>
      </el-table-column>
      <!-- 仓储中心-目标库位名称 -->
      <el-table-column v-else-if="headerItem.key == 'targetGoodsPosCode'" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-table :isEdit="false" TableName="WmsGoodsPosMst" v-model="scope.row.targetGoodsPosCode"></select-table></span>
        </template>
      </el-table-column>
       <!-- 仓储中心-借调履历 -->
      <el-table-column v-else-if="headerItem.key == 'reponse' && isJdll" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <select-type :isEdit="false" typeId="SYS_GOODSCHANGE_REASON" v-model="scope.row.reponse"  ></select-type>
        </template>
      </el-table-column>
      <!-- 其他发货 -->
      <el-table-column v-else-if="headerItem.key == 'applyReponse' && isQtfh" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
           <span  >{{ filterothersendResponse(scope.row.applyReponse)}}</span>
        </template>
      </el-table-column>
      <!-- 其他收货 -->
      <el-table-column v-else-if="headerItem.key == 'applyReponse' && isQtsh" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <!-- <select-type :isEdit="false" typeId="SYS_OTHERSEND_RESPONSE" v-model="scope.row.applyReponse"  ></select-type> -->
           <span  >{{ filterothersendResponse(scope.row.applyReponse)}}</span>
        </template>
      </el-table-column>
      <!-- 包装管理 组包专用 -->
      <el-table-column v-else-if="headerItem.key == 'pickCount' && isZb" :key="index" :label="headerItem.description" align="center" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
           <el-input-number  :step="1" step-strictly   type="text"  size="mini" :disabled="!scope.row.dis" v-model="scope.row.pickCount" :min="0" :max="scope.row.num/scope.row.standNum" > </el-input-number>
          <!-- <select-type :isEdit="false" typeId="SYS_OTHERSEND_RESPONSE" v-model="scope.row.applyReponse"  ></select-type> -->
        </template>
      </el-table-column>


      <!-- 库区管理-品质区分 -->
      <el-table-column v-else-if="headerItem.key=='qualityFlg'" min-width="120px" :label="headerItem.description" :key="index" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_QUALITYFLAG" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 库区管理-库区类型 -->
      <el-table-column v-else-if="headerItem.key=='goodsAreaType'" min-width="120px" :label="headerItem.description" :key="index" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span><select-type :isEdit="false"  typeId="SYS_GOODSAREATYPE" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>   
      </el-table-column>
      <!-- 库区管理-仓库处理 -->
      <!-- <el-table-column v-else-if="headerItem.key=='stockCode' && !isSskc" min-width="200px" :label="headerItem.description" :key="index" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{filterStock(scope.row[headerItem.key])}}
        </template>   
      </el-table-column> -->
      <!-- 库区管理-仓库处理 实时库存-->
      <!-- <el-table-column v-else-if="headerItem.key=='stockCode' && isSskc" min-width="200px" :label="headerItem.description" :key="index" show-overflow-tooltip align="center" sortable :prop="headerItem.key">
        <template slot-scope="scope">
          {{filterStock(scope.row[headerItem.key])}}
        </template>   
      </el-table-column> -->
      <!-- 库区管理-管理员ID -->
      <el-table-column v-else-if="headerItem.key=='managerId'" min-width="120px" :label="headerItem.description" :key="index" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{scope.row[headerItem.key]|filterManager}}</span>
        </template>   
      </el-table-column>
      <!-- 库位管理-库区 -->
      <!-- <el-table-column v-else-if="headerItem.key=='goodsAreaCode' && !isSskc"  :label="headerItem.description" min-width="200px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ filterGoodsArea(scope.row[headerItem.key])}} 
        </template>   
      </el-table-column> -->
      <!-- 库位管理-库区 实时库存-->
      <!-- <el-table-column v-else-if="headerItem.key=='goodsAreaCode' && isSskc"  :label="headerItem.description" min-width="200px" :key="index" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          {{ filterGoodsArea(scope.row[headerItem.key])}} 
        </template>   
      </el-table-column> -->
      <!-- 库位管理-占用标志处理 -->
      <el-table-column v-else-if="headerItem.key=='usedFlg'"  :label="headerItem.description" min-width="120px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="scope.row.usedFlg?'color-danger':'color-success'">{{scope.row[headerItem.key]|filterlotMixtureFlg}}</span>
        </template>   
      </el-table-column>
      <!-- 库位管理-入库禁用处理 -->
      <el-table-column v-else-if="headerItem.key=='putAwayRestricted'"  :label="headerItem.description" min-width="120px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="scope.row.putAwayRestricted?'color-danger':'color-success'">{{scope.row[headerItem.key]|filterforbidden}}</span>
        </template>   
      </el-table-column>
      <!-- 库位管理-出库禁用处理 -->
      <el-table-column v-else-if="headerItem.key=='retrievalRestricted'"  :label="headerItem.description" min-width="120px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="scope.row.retrievalRestricted?'color-danger':'color-success'">{{scope.row[headerItem.key]|filterforbidden}}</span>
        </template>   
      </el-table-column>
      <!-- 库位管理-是否空库位处理 -->
      <el-table-column v-else-if="headerItem.key=='empty'"  :label="headerItem.description" min-width="120px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="scope.row.empty?'color-success':'color-danger'">{{scope.row[headerItem.key]|filterlotMixtureFlg}}</span>
        </template>   
      </el-table-column>
      

      <!-- 销售模块-退货入库-成品退货通知单-相关单据类型处理 -->
      <el-table-column v-else-if="headerItem.key == 'externalType'" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_EXTERNALTYPE" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 销售模块-退货入库-成品退货通知单-入库类型处理 -->
      <el-table-column v-else-if="headerItem.key == 'instockType'" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_INBOUNDTYPE"  v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 销售模块-退货入库-成品退库申请单-入库类型处理 -->
      <el-table-column v-else-if="headerItem.key=='goodsType'" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-type  :isEdit="false" typeId="SYS_GOODSTYPE" v-model="scope.row[headerItem.key]"></select-type></span>
        </template>
      </el-table-column>
      <!-- 销售模块-退货入库-成品退库申请单-入库类型 -->
      <el-table-column v-else-if="headerItem.key=='orderType'" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-type  :isEdit="false" typeId="SYS_INBOUNDTYPE" v-model="scope.row[headerItem.key]"></select-type></span>
        </template>
      </el-table-column>
      <!-- 库位编号 -->
      <!-- <el-table-column v-else-if="headerItem.key=='goodsPosCode' && !isSskc"  :label="headerItem.description" min-width="200px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">                              
          <select-table :isEdit="false" TableName="WmsGoodsPosMst" v-model="scope.row[headerItem.key]"></select-table>
        </template>   
      </el-table-column> -->
      <!-- 库位编号 实时库存-->
      <!-- <el-table-column v-else-if="headerItem.key=='goodsPosCode' && isSskc"  :label="headerItem.description" min-width="200px" :key="index" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">                              
          <select-table :isEdit="false" TableName="WmsGoodsPosMst" v-model="scope.row[headerItem.key]"></select-table>
        </template>   
      </el-table-column> -->
      <!-- 上架操作员 -->
      <el-table-column v-else-if="headerItem.key=='operatorId'"  :label="headerItem.description" min-width="120px" :key="index" align="center" show-overflow-tooltip>
        <template slot-scope="scope">    
          <span>{{scope.row[headerItem.key]}}</span>                          
        </template>   
      </el-table-column>
      <!-- 销售模块-发货出库-成品发货通知单-出库类型 -->
      <el-table-column v-else-if="headerItem.key=='outstockType' || headerItem.key=='outStockType' " :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_OUTSTOTYPE" v-model="scope.row[headerItem.key]"></select-type></span>
        </template>
      </el-table-column>
      <!-- 销售模块-发货出库-成品发货通知单-单据状态    仓储中心-仓库申请单状态 -->
      <el-table-column v-else-if="headerItem.key=='status'" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <!-- <select-type :class="scope.row.status | CstatusFilter" v-if="isCczx" :isEdit="false" typeId="SYS_APPLYSTATUS" v-model="scope.row[headerItem.key]"></select-type> -->
          <span><select-type :class="scope.row.status | CstatusFilter" :isEdit="false" typeId="SYS_ORDERSTATUS" v-model="scope.row[headerItem.key]"></select-type></span>
        </template>
      </el-table-column>
      <!-- 销售模块-发货出库-成品发货通知单-单据号、相关单据号   采购模块-采购上架任务纪录-上架任务单号、物料型号-->
      <el-table-column v-else-if="(headerItem.key=='orderCode' || headerItem.key=='taskCode'|| headerItem.key=='planId')&& !isCgsjrw  " :key="index" :label="headerItem.description" width="180px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 采购模块-采购入库-上架任务记录> -->
      <el-table-column v-else-if="headerItem.key=='taskCode' && isCgsjrw" sortable  :prop="headerItem.key" :key="index" :label="headerItem.description"  width="180px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <el-table-column v-else-if="headerItem.key=='eRPCode' && isCgsjrw"   :prop="headerItem.key" :key="index" :label="headerItem.description"  width="180px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row['erpCode']}}</span>
        </template>
      </el-table-column>
      <!-- 是否生成拣货 -->
       <el-table-column v-else-if="headerItem.key=='createNextYn'"  :prop="headerItem.key" :key="index" :label="headerItem.description"  width="130px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
         <span  :class="scope.row.createNextYn?'color-danger':'color-success'">{{ filterPicking(scope.row.createNextYn)}}</span>
        </template>
      </el-table-column>
      <!-- 销售模块相关单据号 点击事件特殊处理-->
      <el-table-column v-else-if="headerItem.key=='externalNo' &&!isScckjhrw" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <!-- <span v-else-if="headerItem.key === 'privilegeRules'" class="ruleSpan ellipsis" @click.stop="handleUpdate(scope.row)">{{scope.row[headerItem.key]}}</span> -->
          <span v-if="isCgdhtzd" class="ruleSpan ellipsis" @click.stop="handleJumpPage(scope.row)">{{scope.row[headerItem.key]}}</span>
          <span v-else>{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 商品名称-->
      <el-table-column v-else-if="headerItem.key=='goodsName' && !isSskc" :key="index" :label="headerItem.description" min-width="250px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存-商品名称-->
      <el-table-column v-else-if="headerItem.key=='goodsName' && isSskc" :key="index" :label="headerItem.description" min-width="250px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存-包装条码-->
      <el-table-column v-else-if="headerItem.key=='containerId'" :key="index" :label="headerItem.description" min-width="140px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span  class="linkcolor"  @click.stop="handleJumpPage(scope.row)" >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存-商品编号-->
      <el-table-column v-else-if="headerItem.key=='goodsId' && isSskc" :key="index" :label="headerItem.description" min-width="160px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 采购-上架任务记录-商品编号-->
      <el-table-column v-else-if="headerItem.key=='goodsId' && isCgsjrw" :filters="screen"
      :filter-method="filterHandler" sortable  :key="index" :label="headerItem.description" min-width="160px" align="center" show-overflow-tooltip  :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template> 
      </el-table-column>
      <!-- 商品型号-->
      <el-table-column v-else-if="headerItem.key=='goodsModel' && !isSskc" :key="index" :label="headerItem.description" min-width="250px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存-商品型号-->
      <el-table-column v-else-if="headerItem.key=='goodsModel' && isSskc" :key="index" :label="headerItem.description" min-width="250px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-采购退货任务纪录-货物项目编码-->
      <el-table-column v-else-if="headerItem.key=='goodsBatch' && !isSskc" :key="index" :label="headerItem.description" min-width="180px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存-商品项目编码-->
      <el-table-column v-else-if="headerItem.key=='goodsBatch' && isSskc" :key="index" :label="headerItem.description" min-width="180px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存-生产项目编码-->
      <el-table-column v-else-if="headerItem.key=='inBatchCode' && isSskc" :key="index" :label="headerItem.description" min-width="180px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 仓储中心-实时库存 失效日期 -->
      <el-table-column v-else-if="headerItem.key=='ExpireDate' && isSskc" :key="index" :label="headerItem.description" min-width="180px" align="center" show-overflow-tooltip sortable :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 采购模块 -->
      <el-table-column v-else-if="headerItem.key=='venCode' " :key="index" :label="headerItem.description" min-width="300px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 相关单据 -->
      <el-table-column v-else-if="headerItem.key=='xgdj' " :key="index" :label="headerItem.description" min-width="300px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <!-- <span class="ruleSpan ellipsis" @click.stop="handleJumpPage(scope.row)">{{scope.row[headerItem.key]}}</span> -->
          <span v-if="!scope.row.externalType">/</span>
          <span v-else  class="linkcolor"  @click.stop="handleJumpPage(scope.row)">
            <span><select-type :isEdit="false" typeId="SYS_EXTERNALTYPE" v-model="scope.row.externalType" style="display: inline;"></select-type> —— <span >{{scope.row.externalNo}}</span></span>
          </span>
        </template>
      </el-table-column>
      <!-- 是否供应商 -->
      <el-table-column v-else-if="headerItem.key=='isPurchaser' " :key="index" :label="headerItem.description" min-width="100px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
           <span :class="scope.row.isPurchaser?'color-success':'color-danger'">{{filterYesNoStatus(scope.row.isFactory)}}</span>
        </template>
      </el-table-column>
      <!-- 是否客户 -->
      <el-table-column v-else-if="headerItem.key=='isFactory' " :key="index" :label="headerItem.description" min-width="100px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
            <span  :class="scope.row.isFactory?'color-success':'color-danger'">{{filterYesNoStatus(scope.row.isFactory)}}</span>
        </template>
      </el-table-column>
      <!-- 盘点计划的 计划时间 -->
      <el-table-column v-else-if="headerItem.key=='planTime' " :key="index" :label="headerItem.description" min-width="160px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
           <span >{{ scope.row[headerItem.key]?(scope.row[headerItem.key]).substring(0,(scope.row[headerItem.key]).lastIndexOf(' ')):(scope.row[headerItem.key])}}</span>
            
        </template>
      </el-table-column>
      <!-- 地址 -->
      <el-table-column v-else-if="headerItem.key=='address' " :key="index" :label="headerItem.description" min-width="220px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
            <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
       <!-- 数量 -->
      <el-table-column v-else-if="headerItem.key=='num' " :key="index" :label="headerItem.description" min-width="100px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
            <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
       <!-- 单位 -->
      <el-table-column v-else-if="headerItem.key=='standUnitName' " :key="index" :label="headerItem.description" min-width="100px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
            <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 条码 -->
      <el-table-column v-else-if="headerItem.key=='barcode' " :key="index" :label="headerItem.description" min-width="160px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
            <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 是否产品码 -->
      <el-table-column v-else-if="headerItem.key=='isProductLot' " :key="index" :label="headerItem.description" min-width="100px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
            <span  :class="scope.row.isProductLot?'color-success':'color-danger'">{{filterYesNoStatus(scope.row.isProductLot)}}</span>
        </template>
      </el-table-column>
      <!-- 条码管理 -条码类型 -->
      <el-table-column v-else-if="headerItem.key=='barcodeType' " :key="index" :label="headerItem.description" min-width="100px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span><select-type :isEdit="false" typeId="SYS_BARCODETYPE" v-model="scope.row[headerItem.key]" ></select-type></span>
        </template>
      </el-table-column>
      <!-- 条码管理 - 规格 -->
      <el-table-column v-else-if="headerItem.key=='model'" :key="index" :label="headerItem.description" min-width="250px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 条码管理 - 规格 -->
      <el-table-column v-else-if="headerItem.key=='priority'" :key="index" :label="headerItem.description" min-width="250px" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span >{{filterpriorityData(scope.row.priority)}}</span>
        </template>
      </el-table-column>

      <!-- 入库申请单号特殊跳转 -->
      <el-table-column v-else-if="headerItem.key=='relatedCode'" :key="index" :label="headerItem.description" min-width="150px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span v-if="isCgsjrw" class="linkcolor" @click.stop="handleJumpPage(scope.row)">{{scope.row[headerItem.key]}}</span>
          <span v-else>{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 生产出库拣货任务 -->
      <el-table-column v-else-if="headerItem.key=='externalNo' && isScckjhrw" :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span v-if="isScckjhrw" class="linkcolor" @click.stop="handleJumpPage(scope.row)">{{scope.row[headerItem.key]}}</span>
          <span v-else>{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      <!-- 生产领料申请  mes操作人特殊处理 如果   mes操作人== ‘’  则展示 创建人 -->
      <el-table-column v-else-if="headerItem.key=='mesCreateUserName' &&isscllsq " :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]==""||scope.row[headerItem.key]==null?scope.row["createUserName"]:scope.row[headerItem.key]}}</span>   
        </template>
      </el-table-column>
      <!-- 其他的不特殊处理 -->
      <el-table-column v-else-if="headerItem.key=='mesCreateUserName' && !isscllsq " :key="index" :label="headerItem.description" min-width="120px" align="center" show-overflow-tooltip :prop="headerItem.key">
        <template slot-scope="scope">
          <span >{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      

      <!-- 时间处理 -->
      <el-table-column v-else-if=" headerItem.type=='DateTime' || headerItem.key=='pickingStartTime'|| headerItem.key=='pickingEndTime' || headerItem.key=='sourceGoodsBatch' || headerItem.key=='targetGoodsBatch' " :key="index" :prop="headerItem.key" :label="headerItem.description" show-overflow-tooltip min-width="160px" align="center"> </el-table-column>
      <el-table-column v-else-if="headerItem.key=='inStockDate' " :key="index" :label="headerItem.description" min-width="160px" align="center" show-overflow-tooltip :prop="headerItem.key">
          <template slot-scope="scope">
              <span >{{   (scope.row[headerItem.key]).length>19?(scope.row[headerItem.key]).substring(0,(scope.row[headerItem.key]).lastIndexOf(' ')):(scope.row[headerItem.key]) }}</span>
          </template>
      </el-table-column>
      <el-table-column  :label="headerItem.description" min-width="180px" :key="index" show-overflow-tooltip v-else align="center">
        <template slot-scope="scope">
          <!-- enable 特殊处理 -->
          <span v-if="headerItem.key == 'enable'">
            <!-- 仓库管理-有效标志 -->
            <span v-if="isYxWx" :class="scope.row.enable?'color-success':'color-danger'"><span >{{ filterenable(scope.row[headerItem.key])}}</span></span>
            <!-- 字典分类-是否可用 -->
            <span v-else-if="isZdfl"  :class="scope.row.enable?'color-success':'color-danger'">{{ filterBasicState(scope.row.enable)}}</span>
            <!-- 数据权限是否可用 -->
            <span v-else :class="scope.row.enable?'color-success':'color-danger'"> {{ filteruseryn(scope.row[headerItem.key])}}</span>
          </span>
          <!-- 数据权限-权限规则 -->
          <span v-else-if="headerItem.key === 'privilegeRules'" class="ruleSpan ellipsis" @click.stop="handleUpdate(scope.row)">{{scope.row[headerItem.key]}}</span>
          <!-- 客户管理-是否厂家 -->
          <!-- <span v-else-if="headerItem.key == 'isFactory' " :class="scope.row.isFactory?'color-success':'color-danger'">{{scope.row.isFactory?'是':'否'}}</span> -->
          <!-- 客户管理-是否供应商 -->
          <!-- <span v-else-if="headerItem.key == 'isPurchaser'" :class="scope.row.isPurchaser?'color-success':'color-danger'">{{scope.row.isPurchaser?'是':'否'}}</span> -->
          <span v-else>{{scope.row[headerItem.key]}}</span>
        </template>
      </el-table-column>
      
    </template>

    <!-- 操作 -->
    <el-table-column fixed="right" v-if="hasOperation" align="center" :label="'操作'" min-width="180" class-name="small-padding fixed-width" key='hasOperation'>
      <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
        <el-button v-if="scope.row.disable!=true" size="mini" type="danger" @click="handleModifyStatus(scope.row,true)">停用</el-button>
      </template>
    </el-table-column>
  </div>
</template>

<script>
import SelectType from '@/components/SelectType'
import SelectTable from '@/components/SelectTable'
import * as GetUser from '@/api/users'
export default {
  components: { SelectType,SelectTable},
  data() {
    return {
      // InFlgList:[],
    }
  },
  filters: {
    CstatusFilter(status){
      const statusMap = {
        'SYS_ORDERSTATUS_CHECK': 'color-normal',//已审核
        'SYS_ORDERSTATUS_CLOSE': 'color-info',//已关闭
        'SYS_ORDERSTATUS_CREATE': 'color-danger',//未处理
        'SYS_ORDERSTATUS_FINISHED': 'color-yellow',//已完成
        'SYS_ORDERSTATUS_ING': 'color-prepare',//进行中
        'SYS_ORDERSTATUS_PICKING': 'color-prepare',//拣货中
        'SYS_ORDERSTATUS_READY': 'color-warning',//准备中
        'SYS_ORDERSTATUS_STOCKED': 'color-yellow',//备货完成
        'SYS_ORDERSTATUS_STOCKING': 'color-prepare',//备货中
        'SYS_ORDERSTATUS_SYNC': 'color-success',//已同步
        'SYS_WORKING': 'color-normal',
        'SYS_COMPELETE': 'color-success',
        'SYS_PREPAIR': 'color-info',
      }
      return statusMap[status]
    },
    statusFilter(disable) {
      const statusMap = {
        false: 'color-success',
        true: 'color-danger'
      }
      return statusMap[disable]
    },
    filterlotMixtureFlg(val) {
      switch (val) {       
        case true:
          return '是'
        case false:
          return '否'
      }
    },
    filterqualityFlg(val){
      switch (val) {                          
        case '1':
          return '良好'
        case '2':
          return '一般'
      }
    },
    filtergoodsAreaType(val){
      switch (val) {                          
        case '1':
          return '类型1'
        case '2':
          return '类型2'
      }
    },
    
    filterManager(val){   
      let query ={
        id:val
      }
      GetUser.get(query).then(response => {
        this.managerName = response.result.name
        return  response.result.name
      })
    },
    filterforbidden(val){           
      switch (val) {                          
        case false:
          return '未禁用'
        case true:
          return '禁用'
      }
    },
  },
  computed: {
    // 申请原因
    filterothersendResponse(){
      return function (stockType) { 
        if( this.$store.state.othersendResponse.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.othersendResponse.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    // 任务优先级
    filterpriorityData(){
      return function (stockType) { 
        if( this.$store.state.priorityData.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.priorityData.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
     // 申请类型
    filterapplytype(){
      return function (stockType) { 
        if( this.$store.state.applytype.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.applytype.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    // 是否生成拣货
    filterPicking(){
      return function (stockType) { 
        if( this.$store.state.picking.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.picking.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    //返回是否   用于是否客户 供应商  
    filterYesNoStatus(){
      return function (stockType) { 
        if( this.$store.state.yesnoatatus.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.yesnoatatus.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    filterBasicState(){
      return function (stockType) { 
        if( this.$store.state.basicState.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.basicState.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    //有效标志的过滤
    filterenable(){
      return function (stockType) { 
        if( this.$store.state.enable.filter(u=>u.dtCode==stockType).length>0){
          return   this.$store.state.enable.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    filterStock(){
      return function (stockCode) {
        let obj = this.$store.state.stockDatas.filter(u=>u.stockCode== stockCode)
        if(obj.length>0){
          return   this.$store.state.stockDatas.filter(u=>u.stockCode== stockCode)[0].name
        }else{
          return ''
        } 
      }
    },
    filterGoodsArea(){
      return function (goodsAreaCode) {                   
        if(goodsAreaCode==''){
          return ''
        }else{
          if( this.$store.state.goodsareaDatasBase.filter(u=>u.goodsAreaCode== goodsAreaCode).length>0){
            return   this.$store.state.goodsareaDatasBase.filter(u=>u.goodsAreaCode== goodsAreaCode)[0].name
          }else{
            return ''
          }
        }
      }
    },
    // 进出标志过滤
    filterInFlgType(){
      return function (inFlg) { 
        if( this.$store.state.InFlgList.filter(u=>u.dtCode== inFlg).length>0){
          return   this.$store.state.InFlgList.filter(u=>u.dtCode== inFlg)[0].name
        }else{
          return ''
        }
      }
    },
    // 库存变动类型过滤OperateTypeList
    filterOperateType(){
      return function (stockType) { 
        if( this.$store.state.OperateTypeList.filter(u=>u.dtCode== stockType).length>0){
          return   this.$store.state.OperateTypeList.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    //实时库存库存锁定
    filterLockyn(){
      return function (stockType) { 
        if( this.$store.state.lockyn.filter(u=>u.dtCode== stockType).length>0){
          return   this.$store.state.lockyn.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
    //数据权限是否可用
    filteruseryn(){
      return function (stockType) { 
        if( this.$store.state.useryn.filter(u=>u.dtCode== stockType).length>0){
          return   this.$store.state.useryn.filter(u=>u.dtCode== stockType)[0].name
        }else{
          return ''
        }
      }
    },
  },
  props:{
    isBhrw:{//备货任务
      type:Boolean,
      default:false
    },
    isZb:{//组包
      type: Boolean,
      default: false
    },
    isKqbm:{
      type: Boolean,
      default: false
    },
    //用于筛选物料编码
    screen:{
       type: Array,
    },
    // 是否开启多选
    hasSelection: {
      type: Boolean,
      default: true
    },
    // 是否开启单选
    hasRadio: {
      type: Boolean,
      default: false
    },
    //单选绑定值
    hasRadioModel:{
      default: '',
    },
    // 是否开启操作
    hasOperation: {
      type: Boolean,
      default: false
    },
    // 是否是货物移动 
    isHwyd: {
      type: Boolean,
      default: false
    },
    // 是否是仓储中心 status仓库申请单状态
    isCczx: {
      type: Boolean,
      default: false
    },
    //是否是仓库管理 - enable 特殊处理
    isYxWx: {
      type: Boolean,
      default: false
    },
    //是否是字典分类 - enable 特殊处理
    isZdfl: {
      type: Boolean,
      default: false
    },
    //是否是实时库存 - 开启排序 特殊处理
    isSskc: {
      type: Boolean,
      default: false
    },
    //是否是采购到货通知单 - 相关单据号externalNo绑定点击事件
    isCgdhtzd:{
      type: Boolean,
      default: false
    },
    //是否是采购上架任务 - 入库申请单号externalNo绑定点击事件
    isCgsjrw:{
      type: Boolean,
      default: false
    },
     //是否是生产-出库-拣货任务 - 入库申请单号externalNo绑定点击事件
    isScckjhrw:{
      type: Boolean,
      default: false
    },
    //是否是库存履历 - 库存履历：进出标志(Inflg)值是0出库,1入库,2移动。 其他页面是true/false
    isKcll:{
      type: Boolean,
      default: false
    },
    //是否是借调履历
    isJdll:{
      type: Boolean,
      default: false
    },
    //是否是其他发货
    isQtfh:{
      type: Boolean,
      default: false
    },  
    //是否是其他收货
    isQtsh:{
      type: Boolean,
      default: false
    },
    // 是否多选固定
    fix: {
      type: Boolean,
      default: false
    },
    // 是否生产领料申请
    isscllsq: {
      type: Boolean,
      default: false
    },
    headerList:[String, Object,Array],
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    selecheck(row){
      if(this.isZb){
        return  row.dis
      }else if(this.isBhrw){
        if (row.enableNum>0) {
          return true
          }else{
            return false
          }
      }else{
        return true
      }
    },
    // selecheck(row){
    //   if(this.isZb){
    //     return  row.dis
    //   }else{
    //     return true
    //   }
    // },
    // 用于筛选
    filterHandler(value, row) {
        return row.goodsId === value;
      },
    //采购到货通知单 - 相关单据号externalNo绑定点击事件
    handleJumpPage(row){
      this.$emit('handleJumpPage',row)
    },
    //编辑
    handleUpdate(row){
      this.$emit('handleUpdate',row)
    },
    // 停用
    handleModifyStatus(row,status){
      this.$emit('handleModifyStatus',row,status)
    },
    
  },
  
}
</script>
<style scoped  lang="scss">
  .linkcolor{
		color: #1a96d4;
		cursor: pointer;
	}

</style>
