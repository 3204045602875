<template>
  <div>
    <span v-if="!isEdit">{{labelName}}</span>
    <template v-else>
       <template v-if="TableName!='User'">
          <el-select  :size="size" :disabled="disabled" :value="value" @change="handleChange" filterable :placeholder="placeholder">
            <el-option v-for="(item, index) in typeDatas" :key="index" :value="item[defaultProps.value]" :label="item[defaultProps.label]"></el-option>
          </el-select>
        </template>
        <template v-else> 
          <el-select  :size="size" :disabled="disabled" :value="value" @change="handleChange" filterable :placeholder="placeholder">
            <el-option v-for="(item, index) in $store.state.goodsLiable" :key="index" :value="item[defaultProps.value]" :label="item[defaultProps.label]"></el-option>
          </el-select>
          <!-- <div>{{list}}</div> -->
        </template>
    </template>
  </div>
</template>

<script>
  // import * as categorys from '@/api/categorys'
  // import { mapGetters, mapActions } from 'vuex'
  // import * as users from '@/api/users'

  export default {
    name: 'selecttype-table',
    components: {},
    props: {
      placeholder:{
        type:String,
        default:"请选择"
      },
      TableName: [String, Number],
      value: {
        type: [String, Number],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isEdit: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: 'mini'
      },
      defaultProps: {
        type: Object,
        default() {
          if(this.TableName=='WmsStockMst'){ //选择仓库时的下拉选择项
             return {
              label: 'name',
              value: 'stockCode'
            }
          }else if(this.TableName=='WmsGoodsAreaMst'){ //选择库区时的下拉选择项
            return {
              label: 'name',
              value: 'goodsAreaCode'
            }
          }else if(this.TableName=='WmsGoodsPosMst'){   //选择库位时的下拉选择项
            return {
              label: 'name',
              value: 'goodsPosCode'
            }
          }
          else if(this.TableName=='User'){   //责任人搜索
            return {
              label: 'name',
              value: 'id'
            }
          }
         
        }
      },
      HIdProps:{
        type:String,
        default: ''
      }
    },
    data() {
      return {
        list:[],
        typeDatas:[],
      }
    },
    computed: {
      labelName() {
        const item = this.typeDatas.find(item => item[this.defaultProps.value] === this.value)
        return item && item[this.defaultProps.label] || this.value
      }
    },
    watch: {
      TableName() {
        this.getList()
      },
     
    },
    created() {
      this.initView()
    },
    methods: {
      initView() {
        this.getList()
      },
      getList() {
        if(this.TableName=='WmsStockMst'){//仓库
            this.typeDatas=this.$store.state.stockDatas 
              
        }
         if(this.TableName=='WmsGoodsAreaMst'){//库区
          this.typeDatas=this.$store.state.goodsareaDatasBase 
           
        }
         if(this.TableName=='WmsGoodsPosMst'){//库位
             this.typeDatas= this.$store.state.goodsPosDatasBase 
        }
      },
      handleChange(val) {
        this.$emit('input', val)
        //  this.$emit('change-stock',val)
      }
    }
  }

</script>

<style scoped>
</style>
